<template>
  <section id="signout"></section>
</template>

<script>
// Utilities
import { mapActions, mapMutations } from "vuex";

export default {
  methods: {
    ...mapActions("cognito", ["signOut"]),
    ...mapMutations(["setSnackbar"]),
  },
  mounted: function () {
    this.signOut();
    this.setSnackbar({
      type: "success",
      msg: "Du er nu logget ud",
    });
    this.$router.push({ name: "frontpage" });
  },
};
</script>
